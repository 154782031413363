import React, { useEffect } from "react";

const index = () => {
  useEffect(() => {
    window.location.pathname = "/";
  }, []);
  return null;
};

export default index;
